<!--
@author: binchen
@desc：createFansModal
@date: 2023-07-24 20:34:35
-->
<template>
  <a-modal
    title="创建粉丝"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    :footer="false"
    width="665px"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :form="form"
      :model="form"
      :rules="rules"
      :colon="false"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="头像" prop="brandLogoPic">
        <Upload
          ref="brandLogoPic"
          isRound
          isCompress
          width="66px"
          height="66px"
          @success="(e) => uploadSuccess(e, 'brandLogoPic')"
        >
        </Upload>
      </a-form-model-item>

      <a-form-model-item label="品牌名称" prop="brandName">
        <a-input
          style="width: 250px"
          v-model="form.brandName"
          placeholder="请输入"
        />
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="10">
          <a-form-model-item
            label="商标注册证"
            prop="brandRegistPic"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 14 }"
          >
            <Upload
              ref="brandRegistPic"
              width="80px"
              height="80px"
              @success="(e) => uploadSuccess(e, 'brandRegistPic')"
            >
            </Upload>
          </a-form-model-item>
        </a-col>
        <a-col class="gutter-row" :span="10">
          <a-form-model-item
            label="商标授权书"
            prop="brandAuthorizePic"
            :label-col="{ span: 9 }"
            :wrapper-col="{ span: 15 }"
          >
            <Upload
              ref="brandAuthorizePic"
              width="80px"
              height="80px"
              @success="(e) => uploadSuccess(e, 'brandAuthorizePic')"
            />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-form-model-item label="绑定手机号" prop="bindPhone">
        <a-input
          style="width: 250px"
          v-model="form.bindPhone"
          placeholder="请输入"
        />
      </a-form-model-item>
      <a-form-model-item label="登录密码" prop="password">
        <a-input
          style="width: 250px"
          v-model="form.password"
          placeholder="请输入"
        />
      </a-form-model-item>
      <a-form-model-item label="主页背景图" prop="bgm">
        <Upload
          ref="bgm"
          isCompress
          width="80px"
          height="80px"
          @success="(e) => uploadSuccess(e, 'bgm')"
        />
      </a-form-model-item>
      <a-form-model-item label="主页名称" prop="shopName">
        <a-input
          style="width: 250px"
          v-model="form.shopName"
          placeholder="请输入"
        />
      </a-form-model-item>
      <a-form-model-item label="真实姓名" prop="realName">
        <a-input v-model="form.realName" placeholder="请输入" />
        <a-row :gutter="16" style="margin-top: 20px">
          <a-col class="gutter-row" :span="8">
            <Upload
              ref="handIdentityCard"
              width="153px"
              height="86px"
              :showIcon="false"
              uploadText="点击上传手持身份证图片"
              @success="(e) => uploadSuccess(e, 'handIdentityCard')"
            />
          </a-col>
          <a-col class="gutter-row" :span="8">
            <Upload
              ref="frontIdentityCard"
              width="153px"
              height="86px"
              :showIcon="false"
              uploadText="点击上传身份证正面图片"
              @success="(e) => uploadSuccess(e, 'frontIdentityCard')"
            />
          </a-col>
          <a-col class="gutter-row" :span="8">
            <Upload
              ref="backIdentityCard"
              width="153px"
              height="86px"
              :showIcon="false"
              uploadText="点击上传身份证反面图片"
              @success="(e) => uploadSuccess(e, 'backIdentityCard')"
            />
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="简介" prop="intro">
        <div class="textareaBox">
          <textarea
            v-model="form.intro"
            placeholder="简介认证不超过100字符，4行，含标点符号"
            id="myTextarea"
            maxlength="100"
            rows="4"
            @input="limitInput"
          ></textarea>
          <div class="tip">{{ form.intro.length }} / 100</div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="footer">
      <a-button style="width: 150px" @click="handleCancel">取消</a-button>
      <a-button
        type="primary"
        style="width: 150px"
        class="ML12"
        @click="handleOk"
        >确认</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { createFans, editFansInfo } from "@/api/user/fans.js";
import Upload from "@/components/Upload";
export default {
  name: "createFansModal",
  components: { Upload },
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (!this.form.realName) {
        callback(new Error("请输入真实姓名"));
      } else if (!this.form.handIdentityCard) {
        callback(new Error("请上传手持身份证图"));
      } else if (!this.form.frontIdentityCard) {
        callback(new Error("请上传身份证正面"));
      } else if (!this.form.backIdentityCard) {
        callback(new Error("请上传身份证反面"));
      } else {
        callback();
      }
    };
    let validatePass1 = (rule, value, callback) => {
      if (!value) {
        callback("请输入密码");
      } else if (value.length < 8) {
        callback(new Error("密码长度至少为8位数"));
      } else {
        callback();
      }
    };
     let validatePass3 = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/;
      if (!value) {
        callback("请输入手机号");
      } else if (!reg.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      confirmLoading: false,
      form: {
        backIdentityCard: "",
        brandName: "",
        bindPhone: "",
        bgm: "",
        shopName: "",
        brandAuthorizePic: "",
        handIdentityCard: "",
        realName: "",
        password: "",
        brandLogoPic: "",
        brandRegistPic: "",
        frontIdentityCard: "",
        intro: "",
      },
      rules: {
        brandLogoPic: [
          { required: true, message: "请上传头像", trigger: "change" },
        ],
        brandName: [
          { required: true, message: "请输入品牌名", trigger: "blur" },
        ],
        backIdentityCard: [
          { required: true, message: "请上传身份证反面", trigger: "change" },
        ],
        bindPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
           { required: true, validator: validatePass3, trigger: "change" },
        ],
        shopName: [
          { required: true, message: "请输入主页名称", trigger: "blur" },
        ],
        brandAuthorizePic: [
          { required: true, message: "请上传商标授权书", trigger: "change" },
        ],
        handIdentityCard: [
          { required: true, message: "请上传手持身份证图", trigger: "change" },
        ],
        realName: [
          { required: true, validator: validatePass2, trigger: "change" },
        ],
        password: [
          { required: true, validator: validatePass1, trigger: "change" },
        ],
        intro: [{ required: true, message: "请输入简介", trigger: "blur" }],
        brandRegistPic: [
          { required: true, message: "请上传商标注册证", trigger: "change" },
        ],
        frontIdentityCard: [
          { required: true, message: "请上传身份证正面", trigger: "change" },
        ],
      },
      isEdit: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    show(data) {
      if (data) {
        this.isEdit = true;
        this.form = data;
        this.visible = true;
        this.$nextTick(() => {
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              if (
                [
                  "brandLogoPic",
                  "backIdentityCard",
                  "brandAuthorizePic",
                  "handIdentityCard",
                  "brandRegistPic",
                  "frontIdentityCard",
                  "bgm",
                ].includes(key)
              ) {
                this.$refs[key].imageUrl = data[key];
              }
            }
          }
        });
      } else {
        this.isEdit = false;
        this.visible = true;
      }
    },
    handleOk() {
      console.log(this.form);
      if (!this.isEdit) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            createFans(this.form).then((res) => {
              console.log(res);
              if (res.code === 0) {
                this.$message.success("创建粉丝成功！");
                this.visible = false;
                this.$emit("success");
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            editFansInfo(this.form).then((res) => {
              console.log(res);
              if (res.code === 0) {
                this.$message.success("修改粉丝成功！");
                this.visible = false;
                this.$emit("success");
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    handleCancel() {
      [
        "brandLogoPic",
        "backIdentityCard",
        "brandAuthorizePic",
        "handIdentityCard",
        "brandRegistPic",
        "frontIdentityCard",
        "bgm",
      ].forEach((key) => {
        this.$refs[key].imageUrl = "";
      });
      this.form = {
        backIdentityCard: "",
        brandName: "",
        bindPhone: "",
        bgm: "",
        shopName: "",
        brandAuthorizePic: "",
        handIdentityCard: "",
        realName: "",
        password: "",
        brandLogoPic: "",
        brandRegistPic: "",
        frontIdentityCard: "",
        intro: "",
      };
      this.visible = false;
    },
    uploadSuccess(e, type) {
      this.$refs[type].fileList = [
        {
          uid: Math.random(),
          name: e.split(".")[1],
          status: "done",
          url: this.ossUrl(e),
        },
      ];
      this.form[type] = e;
      if (
        this.form.frontIdentityCard &&
        this.form.handIdentityCard &&
        this.form.backIdentityCard &&
        this.form.realName
      ) {
        this.$refs.form.validateField("realName");
      } else {
        this.$refs.form.validateField(type);
      }
      if (
        [
          "frontIdentityCard",
          "handIdentityCard",
          "backIdentityCard",
          "realName",
        ].includes(type)
      ) {
        if (
          this.form.frontIdentityCard &&
          this.form.handIdentityCard &&
          this.form.backIdentityCard &&
          this.form.realName
        ) {
          this.$refs.form.validateField("realName");
        }
      } else {
        this.$refs.form.validateField(type);
      }
    },
    handleRemoveImg(type) {
      this.form[type] = null;
    },
    limitInput(textarea) {
      // 限制字符数为 100
      const maxLength = 100;
      if (textarea.target.value.length > maxLength) {
        textarea.value = textarea.target.value.slice(0, maxLength);
      }

      // 限制行数为 4
      const maxRows = 4;
      const rows = textarea.target.value.split("\n").length;
      if (rows > maxRows) {
        const lastIndex = textarea.target.value.lastIndexOf("\n");
        textarea.target.value = textarea.target.value.slice(0, lastIndex);
      }
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
#myTextarea {
  width: 100%;
  padding-bottom: 10px;
}
.textareaBox {
  position: relative;
  .tip {
    position: absolute;
    bottom: 5px;
    right: 15px;
  }
}
.imgBox {
  width: 153px;
  height: 86px;
  line-height: 86px;
  font-size: 13px;
  img {
    // max-width: 100%;
    // max-height: 100%;
    width: 100%;
    height: 100%;
  }
}
</style>
