import request from "@/utils/request";

const baseUrl = `${process.env.VUE_APP_API_API}/admin`;

const adminUrl = `${process.env.VUE_APP_API_API}/admin`;
// 粉丝列表

export function fansList(data) {
  return request.post(`${baseUrl}/virtual_business/queryBusinessPage`, data);
}
// 创建粉丝

export function createFans(data) {
  return request.post(
    `${baseUrl}/virtual_business/create-virtual-business`,
    data
  );
}
// 粉丝详情

export function fansDetail(businessUserId) {
  return request.get(`${baseUrl}/virtual_business/detail/${businessUserId}`);
}
// 修改粉丝信息

export function editFansInfo(data) {
  return request.post(
    `${baseUrl}/virtual_business/edit-virtual-business`,
    data
  );
}
// 刷数据
export function brushData(data) {
  return request.post(`${baseUrl}/virtual_business/brush-data`, data);
}
// 刷粉丝
export function brushFans(data) {
  return request.post(`${baseUrl}/virtual_business/brush-fans`, data);
}
