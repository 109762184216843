<template>
  <ak-container card class="ak-card-table">
    <a-card>
      <div class="content" v-if="brushType === 'fans'">
        <img class="logo" :src="ossUrl(info.brandLogoPic)" alt="" />
        <div class="right">
          <div style="margin-bottom: 20px">{{ info.shopName }}</div>
          <div>联系方式：{{ info.personalResp.phone }}</div>
        </div>
      </div>
      <div class="content-data" v-else>
        <h3>封面</h3>
        <img :src="ossUrl(info.coverUri)" alt="" />
        <h3>标题</h3>
        <span>{{info.title}}</span>
      </div>
    </a-card>
    <a-form-model
      ref="form"
      layout="inline"
      :form="searchForm"
      :colon="false"
      style="margin-top: 20px"
    >
      <a-form-model-item label="手机号" prop="phone">
        <a-input v-model="searchForm.phone" placeholder="请输入" />
      </a-form-model-item>

      <a-form-model-item label="名称" prop="userName">
        <a-input v-model="searchForm.brandName" placeholder="请输入" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" class="ML12" @click="handleSearch"
          >查询</a-button
        >
        <a-button class="ML12" @click="onResetForm">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <div class="tableBox">
      <div class="top">
        <div class="selectText">已选中{{ selectedRowKeys.length }}个粉丝</div>
        <a-button type="default" @click="createdFans">创建粉丝</a-button>
      </div>
      <a-table
        style="margin-top: 20px"
        :pagination="pagination"
        @change="changePage"
        rowKey="userId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="tabledata"
      >
        <template slot="user" slot-scope="text, record">
          <div class="contentUser">
            <img
              class="logo"
              :src="ossUrl(record.personalResp.headImage)"
              alt=""
            />
            <div class="right" style="text-align: right">
              <div style="margin-bottom: 10px">
                {{ record.personalResp.name }}
              </div>
              <div>{{ record.personalResp.phone }}</div>
            </div>
          </div>
        </template>
        <template slot="logo" slot-scope="text, record">
          <img class="logo" :src="ossUrl(record.logo)" alt="" />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button type="link" @click="handleEdit(record)">编辑</a-button>
        </template>
      </a-table>
    </div>
    <div class="footer" v-if="brushType === 'fans'">
      <a-button
        style="width: 200px"
        type="primary"
        @click="handleSubmit('fans')"
        >确认刷粉丝</a-button
      >
    </div>
    <div class="footer" v-else>
      <a-button
        style="width: 200px; margin: 0 10px"
        type="primary"
        @click="handleSubmitData(1, '点赞')"
        >刷点赞</a-button
      >
      <a-button
        style="width: 200px; margin: 0 10px"
        type="primary"
        @click="handleSubmitData(2, '收藏')"
        >刷收藏</a-button
      >
      <a-button
        style="width: 200px; margin: 0 10px"
        type="primary"
        @click="handleSubmitData(3, '分享')"
        >刷分享</a-button
      >
      <a-button
        style="width: 200px; margin: 0 10px"
        type="primary"
        @click="handleSubmitData(4, '预览')"
        >刷预览</a-button
      >
    </div>
    <CreateFansModal ref="createFansModal" @success="initData" />
  </ak-container>
</template>

<script>
import CreateFansModal from "./createFansModal.vue";
const columns = [
  {
    title: "LOGO",
    dataIndex: "logo",
    width: 80,
    scopedSlots: { customRender: "logo" },
  },
  {
    title: "名称",
    dataIndex: "shopName",
    width: 180,
  },
  {
    title: "绑定用户",
    dataIndex: "user",
    scopedSlots: { customRender: "user" },
    width: 180,
  },
  {
    title: "简介",
    dataIndex: "describe",
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 180,
  },
];
import { mapGetters } from "vuex";
import { fansList, fansDetail, brushFans, brushData } from "@/api/user/fans";
export default {
  name: "Fans",
  props: {
    record: {
      type: Object,
    },
    businessType: {
      type: [String, Number],
      default: 1,
    },
    brushType: {
      type: String,
      default: "fans",
    },
  },
  components: {
    CreateFansModal,
  },
  computed: {
    // ...mapGetters(['businessType']),
  },
  data() {
    return {
      info: {
        brandLogoPic: "",
        shopName: "",
        personalResp: {
          phone: "",
          headImage: "",
          name: "",
        },
      },
      columns,
      selectedRowKeys: [],
      tabledata: [],
      pagination: {
        total: 0,
        pageSize: 50, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["50", "100", "200", "400"], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        current: 1,
      },
      searchForm: {
        phone: "",
        brandName: "",
        page: 1,
        pageSize: 50,
        // nickName: "",
        // applyState: 0,
        // shopName: "",

        // createdTimeStart: "",
        // virtualUser: 0,

        // applyTimeOrder: "",
        // name: "",
        // createdTimeEnd: "",
        // settledTimeOrder: "",
        // state: 0,

        businessType: 1,
        // businessTag: 0,
      },
    };
  },
  created() {
    this.searchForm.businessType = this.businessType;
    console.log(this.record);
    if (this.brushType === "fans") {
      this.info = this.record;
    } else {
      this.info.coverUri = this.record.coverUri;
      this.info.title = this.record.title;
      this.info.trendIssueId = this.record.trendIssueId;
    }

    this.initData();
  },
  mounted() {},
  methods: {
    changePage(val) {
      console.log(val)
      this.searchForm.page = val.current;
      this.searchForm.pageSize = val.pageSize;
      this.pagination.pageSize = val.pageSize;
      this.pagination.current = val.current;
      this.initData();
    },
    handleSearch() {
      this.searchForm.page = 1;
      this.searchForm.pageSize = 50;
      this.pagination.pageSize = 50;
      this.pagination.current = 1;
      this.initData();
    },
    initData() {
      fansList(this.searchForm).then((res) => {
        if (res.code === 0) {
          this.tabledata = res.data.list;
          this.pagination.total = res.data.total;
        }
      });
    },
    onResetForm() {
      this.searchForm.brandName = "";
      this.searchForm.phone = "";
      this.handleSearch();
    },
    createdFans() {
      this.$refs.createFansModal.show();
    },
    handleEdit(row) {
      console.log(row);
      fansDetail(row.userId).then((res) => {
        if (res.code === 0) {
          this.$refs.createFansModal.show(res.data);
        }
      });
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleSubmit() {
      if (!this.selectedRowKeys.length)
        return this.$message.info("至少选择一个粉丝！");
      console.log(this.selectedRowKeys);
      const params = {
        businessUserId: this.record.userId,
        userIds: this.selectedRowKeys,
      };
      brushFans(params).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success("刷粉丝成功！");
          this.selectedRowKeys = []
          this.initData();

        }
      });
    },
    handleSubmitData(type, name) {
      console.log(this.selectedRowKeys);
      if (!this.selectedRowKeys.length)
        return this.$message.info("至少选择一个粉丝！");
      const params = {
        trendIssueId: this.record.trendIssueId,
        userIds: this.selectedRowKeys,
        type,
      };
      brushData(params).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success(`刷${name}成功！`);
          this.selectedRowKeys = []
          this.initData();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-card-body {
  padding: 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}
.content {
  display: flex;
  align-items: center;
  color: #333;
  .logo {
    margin-right: 10px;
  }
 
}
 .content-data{
    display: flex;
    align-items: center;
    h3{
      display: inline-block;
      margin: 0;
      margin-right: 30px;
      font-weight: 700;
    }
    img{
      width: 60px;
      height: 60px;
      margin-right: 30px;
    }
  }
.tableBox {
  margin-top: 20px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .selectText {
    color: red;
    font-weight: bold;
  }
  .contentUser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      margin-right: 10px;
    }
  }
}
.footer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
